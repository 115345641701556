<template>
    <div class="greyColor">
        <to-history :prop-message="title"></to-history> 
        <div class="search_wap">
          
            <a-select
                v-model="teamor"
                show-search option-filter-prop="children"
                placeholder="请选团队"
                style="width: 200px"
            >
                <a-select-option
                    v-for="(item, index) in teamList"
                    :key="index"
                    :value="item.did"
                >
                    {{ item.name }}
                </a-select-option>
            </a-select>
          
            <a-select v-model="type" style="width: 60px">
                <a-select-option style="width: 60px" :value="1"
                    >年</a-select-option
                >
                <a-select-option style="width: 60px" :value="2"
                    >月</a-select-option
                >
                <a-select-option style="width: 60px" :value="3"
                    >周</a-select-option
                >
            </a-select>
            <a-icon type="search" style="margin:0 20px" @click="onSearch"/>
            <!-- <a-button @click="onSearch">搜索</a-button> -->

        
        </div>
        <div class="pos_relate">
              <div v-infinite-scroll="getTask"
               infinite-scroll-disabled="dataLoadStop"
               infinite-scroll-distance="50">
            <div class="lists" v-for="(item, index) in data" :key="index">
                <div class="list_row" v-for="(value, name) in item" :key="name">
                    <!-- @click="showDetail(item.tmjcpb_corp__ids)" -->
                    <div
                        class="noselect"
                        v-if="
                            name != 'id' &&
                            name != 'type' &&
                            name != 'endtime' &&
                            name != 'sname'
                        "
                    >
                        {{ name | dataFiltter }} {{ value }}
                    </div>
                </div>
                <div class="clear"></div>
                <!-- <div class="btn_con">
                    <a-button type="primary" @click="showDetail(item.id)" ghost>编辑</a-button>
                    <a-button type="danger" ghost>删除</a-button>
                </div> -->
            </div>
             <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div>

        </div>
            <!-- <a-pagination
                v-if="data.length>0"
                class="page_select"
                v-model="pagination.current"
                :total="pagination.total"
                @change="pagesChange"
            /> -->
        </div>
    </div>
</template>
<script>
let timeOnj = [
    { key: 1, label: "年" },
    { key: 2, label: "月" },
    { key: 3, label: "周" },
];
// let all =  [{name: "corp__name",show: false,text: "企业名称",type: "text"}];
// import $ from "jquery";
const columns = [
    { title: "新客数", dataIndex: "newcustomers", key: "2" },
    { title: "签单数", dataIndex: "signlistnum", key: "1" },
    { title: "签单金额", dataIndex: "signlistmoney", key: "3" },
    { title: "回款数", dataIndex: "paymentnum", key: "4" },
    { title: "回款金额", dataIndex: "paymentmoney", key: "5" },
    { title: "时间类型", dataIndex: "timetype", key: "6" },
    { title: "目标的执行人", dataIndex: "stname", key: "7" },
    { title: "目标开始时间", dataIndex: "createtime", key: "8" },
    {
        title: "操作",
        key: "operation",
        fixed: "right",
        width: 300,
        scopedSlots: { customRender: "Action" },
    },
];
import { requestXml } from "../../../assets/js/request";
import infiniteScroll from "vue-infinite-scroll";

export default {
     directives: {infiniteScroll},  
    data() {
        return {
            currentPage: 2,
            columns,
            clientList: [], //员工列表
            staff_id: "", //员工id

            teamor: "", //团体id
            teamList: [], //团体列表
            key: "", //执行团队
            type: null,

            data: [],

            // 分页
             dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
               pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                // showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.changePage(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {
                    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
            },
            title:this.$route.query.title
        };
    },
    mounted: function () {
        this.getClientList();
        this.getTask();
    },
    methods: {
        pagesChange(current) {
            let pages = { ...this.pagination };
            pages.current = current;

            this.pagination = pages;
            this.getTask();
            console.log("currentcurrent", current, this.pagination);
            // this.current = current;
        },
        // 获取 简化成员列表
        getClientList() {
            requestXml("/scrm/Target/department", "POST", (res) => {
                console.log("teamListteamList", res);
                this.teamor = res[0].did;
                this.teamList = res;
            });
        },
        //获取时间
        getTime(time) {
            let current = timeOnj.filter((item) => {
                return item.key == time;
            });
            return current;
        },

        // 搜索
        onSearch() {
            this.data=[];
            this.dataLoadNomore=false;
            this.pagination.current=1;
            this.getTask(this.key, this.type);
        },
        reset() {
            this.key = "";
            this.type = null;
            this.status = null;
            this.teamor = "";
        },
        getTask() {
            this.dataLoadStop = true;
            let times = this.getTime(this.type);
            let postdata = {
                timetype: "",
                department_id: this.teamor,
                type: 2,
            };
            if (times[0]?.key) {
                postdata.timetype = times[0]?.label;
            }

            requestXml(
                "/scrm/Target/show",
                "POST",
                (res) => {
                    // for (let i = 0; i < res.list.length; i++) {
                    //     res.list[i].start = getDateTime(res.list[i].start);
                    //     res.list[i].end = getDateTime(res.list[i].end);

                    //     res.list[i].status = this.taskStatus[
                    //         Number(res.list[i].status)
                    //     ];
                    //     res.list[i].type = this.taskType[
                    //         Number(res.list[i].type - 1)
                    //     ];
                    // }
                    this.pagination.total = Number(res.page.total);
                    this.pagination.pageSize = Number(res.page.pagesize);
                    // this.data = res.list;
                    this.data = this.data.concat(res.list);
                    if(res.page.maxpage>this.pagination.current){
                        this.pagination.current += 1
                        this.dataLoadStop = false
                    }else{
                        this.dataLoadNomore = true
                    }
                    // console.log("this.data", res);
                    // console.log('this.data',res.data.list);
                },
                {
                    ...postdata,
                    page: {
                        curpage: this.pagination.current,
                        // pagesize: 999,
                        pagesize: this.pagination.pagesize,
                    },
                }
            );
        },
        // 分页
        changePage(current) {
            //current参数表示是点击当前的页码，
            this.pagination.current = current;
            this.getTask(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            console.log(current, pageSize);
            this.pagination.current = current;
             this.pagination.pageSize = pageSize;
            this.getTask(); //向后端发送请求
        },
        // 查看 type 1 查看 2 编辑
        showDetail(id, type = 2) {
            this.$router.push({
                path: "/scrm_wap/target/groupdetail",
                query: {
                    id: id,
                    type: type,
                },
            });
        },
        // 编辑
        editTask(id) {
            this.$router.push({
                path: "/scrm_pc/task/taskAdd",
                query: {
                    id: id,
                },
            });
        },
        // 关闭
        off(id) {
            requestXml(
                "/scrm/Task/setClose",
                "POST",
                (res) => {
                    console.log(res);
                    this.getTask();
                },
                { id: id }
            );
        },
        // 删除
        onDelete(id) {
            requestXml(
                "/scrm/Target/personaldel",
                "POST",
                (res) => {
                    this.pagination.current=1;
                    this.data=[];
                    console.log(res);
                    this.getTask();
                },
                { id: id }
            );
        },
    },
    watch: {},
    filters: {
        dataFiltter(val) {
            if (val != "id") {
                let all = columns;

                for (var i = 0; i < all.length; i++) {
                    if (val == all[i].dataIndex) {
                        return all[i].title + ":";
                    }
                }
            }
        },
    },
};
</script>
<style scoped>
.page_select {
    position: fixed;
    bottom: 65px;
    display: flex;
    justify-content: center;
    width: 100%;
    background: #fff;
}
.noselect {
    user-select: none;
}
.app {
    background: #f2f2f2;
}
.greyColor {
    background-color: #f5f5f5;
}
body {
    background-color: #f5f5f5;
}
.action {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 5px;
    background: #dceeff;
    border: 1px solid #36a3ff;
    color: #36a3ff;
    border-radius: 5px;
}
.del {
    background: #ffdcdc;
    border-color: #ff4c4c;
    color: #ff4c4c;
}

.search_wap {
    text-align: left;
    margin-bottom: 15px;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.search_wap .ant-input-search {
    width: 50vw;
    margin: 0 5px;
}
.search_wap .ant-select {
    width: 30vw;
}
.pos_relate {
    position: relative;
    height: calc(100vh - 56px);
    overflow: auto;
}
.lists {
    width: 92%;
    /* height: 26rem; */
    padding: 3rem;
    margin: 15px auto;
    background-color: #fff;
    border-radius: 10px;
    color: #202020;
}
.lists .list_row div {
    /* float: left;
        width: 33.33%; */
    /* height: 6rem;
    line-height: 6rem; */
    text-align: left;
    padding: 5px 0;
    
}

.btn_con {
    text-align: left;
}
.btn_con button {
    /* color: #36a3ff; */
    /* border-color: #36a3ff; */
    margin: 1rem;
}

/* .ant-modal{
        width: 80%;
    } */
.tMenu {
    position: fixed;
    bottom: 3rem;
    width: calc(100% - 6rem);
    margin: 3rem 3rem 0;
    height: 50px;
    background: #fff;
    border-radius: 4px;
    color: #36a3ff;
    font-size: 1.2rem;
    justify-content: space-around;
}
.tMenu div {
    flex-direction: column;
    align-items: center;
}
.tMenu img {
    width: 18px;
    height: 18px;
    margin: 5px 0;
}
</style>

